// Formato estrellas (Tab hoteles)
export const splitEstrellas = (textoEstrellas: string | undefined): { countEstrellas: number, rightString: string | null } => {
  const parts = textoEstrellas?.split(' ') ?? [] // dividir el string en un array
  const countEstrellas = parts[0]?.split('*')?.length ? parts[0].split('*').length - 1 : 0 // obtener la cantidad de asteriscos
  const rightString = parts[1] || null // obtener el string de la parte derecha del espacio, si existe
  return { countEstrellas, rightString }
}

// Obtener array precios
export const extractIdAndPrice = (hotels: HotelRecommended[]): Array<{ id: number, price: string }> => {
  return hotels?.map(hotel => ({
    id: hotel.id,
    price: hotel.price
  }))
}

// Obtener precio desde id
export const getPriceById: any = (id: number, hotels: HotelRecommended[]): string | undefined => {
  return hotels.find(hotel => hotel.id === id)?.price
}

// Obtener info hoteles
export const extractHotelInfo = (dataHoteles: any): any => {
  const tabs = dataHoteles?.data?.destination?.map((item: { name: string }) => ({ ...item, label: item.name }))
  const stars = dataHoteles?.data?.stars
  const lista = dataHoteles?.data?.hotel?.reduce((acc: any[], hotel: { destination: string }) => {
    const count = acc.filter(h => h.destination === hotel.destination)?.length
    if (count < 4) {
      acc.push(hotel)
    }
    return acc
  }, [])
  const precio = extractIdAndPrice(lista)

  return { tabs, stars, lista, precio }
}

// Ordenar ciudades tabs
export const ordenarCiudades = (ciudades: TabHoteles[], ordenCiudades: {name: string, destination_id: number, orden: number}[] ): TabHoteles[] => {
  return ciudades?.sort((a: any, b: any) => {
    const ordenA: any = ordenCiudades.find((o: any) => o.destination_id === a.id)?.orden
    const ordenB: any = ordenCiudades.find((o: any) => o.destination_id === b.id)?.orden

    if (ordenA < ordenB) {
      return -1
    }
    if (ordenA > ordenB) {
      return 1
    }
    return 0
  })
}

// Ordenar hoteles search
export const ordenarSearch = [
  null, // Sin orden (Relevancia)
  (a: any, b: any) => { // Precio (menor a mayor)
    if(!a || !b) return 0
    return primerPrecio(a) - primerPrecio(b)
  },
  (a: any, b: any) => { // Precio (mayor a menor)
    return ultimoPrecio(b) - ultimoPrecio(a)
  },
  (a: any, b: any) => { // Estrellas (menor a mayor)
    return a.stars?.order - b.stars?.order
  },
  (a: any, b: any) => { // Estrellas (mayor a menor)
    return b.stars?.order - a.stars?.order
  }
]

// Obtener primer precio del primer pack disponible
export const primerPrecio = (hotel: Hotel): number => {
  if (!hotel) { return 0 }
  const packMinPrice = hotel?.pack_min_price;
  const firstKeyWithPrice: string | undefined = Object.keys(packMinPrice).find(key => packMinPrice[key].price || packMinPrice[key].price_label)
  const firstPriceOrLabel = firstKeyWithPrice ? packMinPrice[firstKeyWithPrice] : undefined

  return firstPriceOrLabel?.price ?? 0
}

// Obtener pack hora del primer precio del primer pack disponible
export const primerPrecioPack = (hotel: Hotel): number => {
  if (!hotel) { return 0 }
  const packMinPrice = hotel?.pack_min_price;
  const firstKeyWithPrice: any = Object.keys(packMinPrice).find(key => packMinPrice[key].price || packMinPrice[key].price_label)
  return firstKeyWithPrice
}

// Obtener primer precio label del primer pack disponible
export const primerPrecioLabel = (hotel: Hotel): string => {
  if (!hotel) { return '' }
  const packMinPrice = hotel?.pack_min_price;
  const firstKeyWithPrice: any = Object.keys(packMinPrice).find(key => packMinPrice[key].price || packMinPrice[key].price_label)
  const firstPriceOrLabel = packMinPrice[firstKeyWithPrice]

  return firstPriceOrLabel?.price_label ?? ''
}

// Obtener último precio del último pack disponible
export const ultimoPrecio = (hotel: Hotel): number => {
  return hotel.pack_min_price[Object.keys(hotel.pack_min_price)[Object.keys(hotel.pack_min_price).length - 1]].price ?? 0
}

// Obtener array de horarios dado un hotel
export const getHorasHotel = (hotel: Hotel, idioma: string): Object => {
  return Object.keys(hotel?.checkin_times || {}).reduce((acc: { [key: string]: string[] }, key: string) => {
    acc[key] = Object.keys(hotel?.checkin_times[key] || {}).filter(subKey => hotel?.checkin_times[key][subKey] === true).map(subKey => changeHourFormat(idioma) ? format12Hour(subKey) : subKey)
    return acc
  }, {})
}

// Obtiene el primer pack que tiene horarios disponibles
export const getFirstNonEmptyKey = (checkin_times: CheckinTimes) => {
  for (const key in checkin_times) {
    if (Object.keys(checkin_times[key]).length > 0) {
      return key;
    }
  }
  return null;
}

// Diferencia entre horas
export const diffHours = (start: string, end: string): number => {
  // Convertir las cadenas a objetos Date
  const startDate: any = createDateFromString(start)
  const endDate: any = createDateFromString(end)

  // Calcular la diferencia en milisegundos
  const difference = endDate - startDate

  // Convertir la diferencia de milisegundos a horas
  const hoursDifference: number = difference / (1000 * 60 * 60)

  return hoursDifference
}

export const createDateFromString = (time: string) => {
  const parts: any = time.split(':')
  return new Date(0, 0, 0, parts[0], parts[1])
}

// Obtener query de url de un destination
export const getDestinationInfo = async (destination_id: number, locale: Ref): Promise<{ query: string, destinationData: Destination | null}> => {
  if(destination_id){
    const { data: destinationData }: any = await useFetch(`${useBaseUrl()}/destination-id`, {
      headers: { "Content-Type": "application/json" },
      query: { 
        destination_id,
        locale
      },
      onResponseError(res) {
        console.log("Error en respuesta:", res)
      },
      onRequestError(err) {
        console.log("Error en solicitud:", err)
      }
    })

    if (!destinationData.value?.data) {
      console.error("Error: No se han recibido datos de la API", destinationData)
      return { query: '', destinationData: null }
    }

    const location = destinationData.value?.data?.location
    const [latitude, longitude] = location ? location.split(',') : [null, null]

    const objQuery: any = {
      'data-destinationId': destinationData.value?.data?.element_id,
      'data-longitude': longitude,
      'data-latitude': latitude,
      'data-distance': destinationData.value?.data?.search_distance,
      'data-search': destinationData.value?.data?.name_translated,
      'data-country': destinationData.value?.data?.country_name_translated,
      'search': destinationData.value?.data?.name_translated,
    }

    return {
      query: Object.keys(objQuery).map(key => objQuery[key] ? `${key}=${objQuery[key]}` : '').filter(Boolean).join('&'),
      destinationData: destinationData.value?.data
    }
  }
  return { query: '', destinationData: null }
}

export const showExtraBetween = <T extends { start_time: string, end_time: string }>(ranges: Array<T>, time: string): boolean => {

  if(ranges.length === 0) return true

  const [hours, minutes] = time.split(':').map(Number)
  const givenTime = new Date()
  givenTime.setHours(hours, minutes, 0, 0)

  const show = ranges.some((range: { start_time: string, end_time: string }) => {
    const [startHours, startMinutes] = range.start_time.split(':').map(Number)
    const [endHours, endMinutes] = range.end_time.split(':').map(Number)

    const startTime = new Date()
    startTime.setHours(startHours, startMinutes, 0, 0)

    const endTime = new Date()
    endTime.setHours(endHours, endMinutes, 0, 0)

    return givenTime >= startTime && givenTime <= endTime
  })

  return show
}

export function removeCookieFileds(data: any) {
  const newData = { ...data }
  delete newData.embeded_html // quitar parametros por tamaña de cookie
  delete newData.description
  return newData
}