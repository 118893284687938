export const useSelectedDayStore = defineStore('selectedDay', () => {
  
  // Estado reactivo
  const selectedDay = ref<Date | null>(new Date())
  const selectedDestStore = useDestinationStore()
  const { selectedDestination } = storeToRefs(selectedDestStore)
  const date = computed(() => selectedDay.value ? formatDateApi(selectedDay.value) : formatDateApi(new Date()))  
  var selectedDiaCookie: Ref<Date> = useCookie('selectedDia', { expires: new Date(new Date().setHours(23, 59, 59, 999)) }) // cookie fecha, expira al final del día
  
  const today = new Date()
  const tomorrow = new Date(today)
  
  const horaCambio = computed(() => {
    const latamCountriesHour = ['AR', 'BR', 'CL', 'MX']
    if (latamCountriesHour.includes(selectedDestination.value?.country_id ?? '')) {
      return 18
    }
    return selectedDestination.value?.country_id === 'ES' ? 15 : 12
  })

  // Funciones
  const init = () => {
    const currentHour = new Date().getHours()
    tomorrow.setDate(today.getDate() + 1)

    const route = useRoute()

    // Si hay una fecha guardada en la cookie
    if(selectedDiaCookie.value){
      const cookieDate = new Date(selectedDiaCookie.value)
      if (isPrevToday(cookieDate)) {
        selectedDiaCookie.value = today
      }
      selectedDay.value = selectedDiaCookie.value
    }

    // Si la hora actual es mayor o igual a la hora de cambio y no hay fecha seleccionada
    if (currentHour >= horaCambio.value && !selectedDay.value) {
      selectedDay.value = tomorrow
    }

    // Si la fecha viene en la URL
    if (route.query['data-checkinDate']) {
      selectedDay.value = getCookieDate(String(route.query['data-checkinDate']))
    }
  }

  const setDay = (date: Date) => {
    if (isPrevToday(date)) { // Si la fecha es anterior a hoy
      date = today
    }
    selectedDay.value = date
    selectedDiaCookie.value = date
  }

  const setNextDay = () => {
    const nextDay = selectedDay.value ? new Date(selectedDay.value) : new Date()
    nextDay.setDate(nextDay.getDate() + 1)
    setDay(nextDay)
  }

  const setPrevDay = () => {
    const prevDay = selectedDay.value ? new Date(selectedDay.value) : new Date()
    prevDay.setDate(prevDay.getDate() - 1)
    setDay(prevDay)
  }

  const setToday = () => {
    setDay(today)
  }

  const setTomorrow = () => {
    setDay(tomorrow)
  }

  const isToday = () => {
    const currentDate = selectedDay.value ? new Date(selectedDay.value) : new Date()
    return currentDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
  }

  const isPrevToday = (date: Date) => {
    return date.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)
  }

  return {
    selectedDay,
    date,
    init,
    setDay,
    setNextDay,
    setPrevDay,
    setToday,
    setTomorrow,
    isToday
  }
})