// Input: Date -> Output: dd/mm/yyyy
export const formatDate = (selectedDia: Date) => {
  const selected = new Date(selectedDia)
  const day = selected.getDate()
  const month = selected.getMonth() + 1
  const year = selected.getFullYear()
  return `${day}/${month}/${year}`
}

// Input: Date -> Output: dd-mm-yyyy
export const formatDateGuion = (selectedDia: Date): string => {
  if(selectedDia){
    const selected = new Date(selectedDia)
    const day = selected.getDate().toString().padStart(2, '0')
    const month = (selected.getMonth() + 1).toString().padStart(2, '0') // devuelve el mes con un cero adelante si es menor a 10
    const year = selected.getFullYear()
    return `${day}-${month}-${year}`
  }else{
    return ''
  }
}

// Input: Date -> Output: yyyy-mm-dd
export const formatDateApi = (selectedDia: Date): string => {
  if(selectedDia){
    const selected = new Date(selectedDia)
    const day = selected.getDate().toString().padStart(2, '0')
    const month = (selected.getMonth() + 1).toString().padStart(2, '0') // devuelve el mes con un cero adelante si es menor a 10
    const year = selected.getFullYear()
    return `${year}-${month}-${day}`
  }else{
    return ''
  }
}

// Input: dd-mm-yyyy -> Output: yyyy-mm-dd
export const convertDateFormat = (input: string): string => {
  const [day, month, year] = input?.split("-") ?? formatDateGuion(new Date())
  return `${year}-${month}-${day}`;
}

// Formatear fecha para el search
export const formatFakeDate = (selectedDia: Date): string => {
  const selected = new Date(selectedDia)
  const day = selected.getDate()
  const month = selected.getMonth()
  const monthChar = new Date(0, month).toLocaleString('default', { month: 'short' })?.substring(0, 3)
  const capitalizedMonth = monthChar?.charAt(0).toUpperCase() + monthChar?.slice(1)
  return `${day} ${capitalizedMonth}.`
}

// Obtener si es de 12 o 24 horas
export const changeHourFormat = (idioma: string): boolean => {
  const format = new Intl.DateTimeFormat(idioma, { hour: 'numeric' }).resolvedOptions().hourCycle;
  if (format === 'h11' || format === 'h12') {
    return true
  }
  return false
}

// Input HH:mm -> Output HH:mm AM/PM
export const format12Hour = (time: string): string => {
  const [hours, minutes] = time.split(':');
  return `${((+hours % 12) || 12)}:${minutes} ${+hours < 12 ? 'AM' : 'PM'}`
}

// Input dd-mm-yyyy -> Output Date
export const getCookieDate = (fechaGuion: string): Date => {
  if (!fechaGuion.match(/^\d{2}-\d{2}-\d{4}$/)) { // verificar que la fecha tenga el formato dd-mm-yyyy
    return new Date()
  }
  const hoy = new Date()
  const dateString: any = fechaGuion
  const dateParts = dateString?.split("-")
  const mifecha = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

  if(mifecha < hoy){ // verificar que la fecha es menor que hoy
    return hoy
  }

  return mifecha
}