// Obtener moneda por país
export const getCurrencyByCountry = async (country: string): Promise<string> => {
  const data = await $fetch('/api-web/currencies') // obtener monedas
  const moneda = data?.find((item) => item.country_id === country)?.currency // elegir moneda dependiendo del país elegido
  return moneda ?? 'EUR'
}

// Obtener moneda por país
export const getLangByCountry = async (country: string): Promise<string> => {
  const { data } = await useLazyFetch('/api-web/currencies') // obtener monedas
  const lang = data.value?.find((item) => item.country_id === country)?.lang // elegir moneda dependiendo del país elegido
  return lang?.toLowerCase() ?? 'es'
}